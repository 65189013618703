import { useParams } from 'react-router-dom'

const slugMap = {
  'seahorse-xf-pro': 'https://interactive.dcasf.com/demo/seahorse_xf_pro'
}

export const Demo = () => {
  const params = useParams()
  return (
    <>
      <iframe className='responsive-iframe' src={slugMap[params.slug]} title='interactive' />
    </>
  )
}
