import AgilentLogo from '../assets/Logo-White-Tagline.svg'
import { Button } from 'react-bulma-components'

import './Landing.css'

export const Landing = () => {
  const openNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
  return (
    <>
      <div id='header'>
        <div id='headerInner'>
          <div id='logo'>
            <img src={AgilentLogo} alt='Agilent Logo' />
          </div>
        </div>
      </div>
      <div className='wrapper'>
        <div id="wrapperHeader">
          <p>Welcome to our Virtual Interactive Demonstration Center for Agilent Seahorse XF Solutions</p>
        </div>
        <div id="wrapperInner">
          <div id="demoContainer">
            <div id="containerHeader">
              <p>Navigate through the Seahorse XF workflow by hovering your mouse above the blue dots in the virtual tour below</p>
            </div>
            <div id="content">
              <Button onClick={() => openNewTab(`/demo/seahorse-xf-pro`)} id="seahorseBtn">Seahorse XF Pro Workflow</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
