import './App.css'
import { Routes, Route } from 'react-router-dom'
import { Landing } from './components/Landing'
import { Demo } from './components/Demo'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/demo'>
        <Route path=':slug' element={<Demo />} />
      </Route>
    </Routes>
  )
}

export default App
